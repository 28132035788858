import type { ReactNode } from 'react';
import { createContext, useContext, useMemo } from 'react';
import type { ProductDynamicData } from '../../graphql/codegen';
import { useProductDynamicDataQuery } from '../../graphql/codegen';
import { useAppContext } from '../static/AppContext';

interface PDPDynamicProviderInterface {
  code: string;
  children: ReactNode;
}

type PDPDynamicContextType = {
  dynamicProductData: ProductDynamicData;
};

const PDPDynamicContext = createContext<PDPDynamicContextType>({
  dynamicProductData: {},
});

export const usePDPDynamicContext = () => useContext(PDPDynamicContext);

export const PDPDynamicContextProvider = ({ code, children }: PDPDynamicProviderInterface) => {
  const { locale, siteType } = useAppContext();
  const { loading, error, data } = useProductDynamicDataQuery({
    variables: {
      siteType,
      locale,
      code,
    },
    ssr: false,
  });

  let dynamicProductData = useMemo(() => ({}), []);

  if (!loading && !error) {
    dynamicProductData = data?.productDynamicData || {};
  }

  const value = useMemo(
    () => ({
      dynamicProductData,
    }),
    [dynamicProductData]
  );

  return <PDPDynamicContext.Provider value={value}>{children}</PDPDynamicContext.Provider>;
};
