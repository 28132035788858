import type { ReactNode, ReactElement } from 'react';
import styled, { css } from 'styled-components';
import { DialogOverlay, DialogContent } from '@reach/dialog';
import { colors, media } from '../styles';

export type DialogPositionType = 'center' | 'top';

export interface DialogProps {
  children: ReactNode;
  ariaLabel: string;
  overlayColor?: string;
  position?: DialogPositionType;
  maxWidth?: string;
  className?: string;
  onClose: () => void;
  testId?: string;
  alignItemCenter?: boolean;
}

const S = {
  DialogOverlay: styled(DialogOverlay)<{ $overlayColor: string; $alignItemCenter: boolean }>`
    position: fixed;
    inset: 0;
    top: var(--smart-banner-height);
    overflow: auto;
    background-color: ${({ $overlayColor }) => $overlayColor};
    z-index: 10018;
    ${({ $alignItemCenter }) =>
      $alignItemCenter &&
      css`
        display: flex;
        align-items: center;
      `}
  `,
  DialogContent: styled(DialogContent)<{
    $verticalPosition: string;
    $maxWidth?: string;
  }>`
    background: ${colors.WHITE};
    outline: none;
    padding: 0;
    width: 100%;
    max-width: ${({ $maxWidth }) => $maxWidth || '750px'};
    margin: auto;
    position: relative;

    ${({ $verticalPosition }) =>
      $verticalPosition === 'center' &&
      css`
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      `}

    @media ${({ theme }) => media(theme).lessThan('sm')} {
      height: 100%;
    }
  `,

  DialogClose: styled.button`
    position: absolute;
    border: none;
    outline: none;
    width: 42px;
    height: 42px;
    top: 0;
    right: 0;
    background-color: ${({ theme }) => theme.colors.content.component['dialog-close'].base};
    cursor: pointer;
    z-index: 1;

    &::before {
      color: ${({ theme }) => theme.colors.content.component['dialog-close'].color};
      font-family: gstar-icons;
      content: '\\F13F';
    }
  `,
};

export const Dialog = ({
  children,
  overlayColor = colors.DIALOG_GREY,
  ariaLabel,
  position = 'center',
  maxWidth,
  onClose,
  className = '',
  testId = undefined,
  alignItemCenter = false,
}: DialogProps): ReactElement => (
  <S.DialogOverlay
    id="dialog-overlay"
    onDismiss={onClose}
    $overlayColor={overlayColor}
    data-testid={testId}
    $alignItemCenter={alignItemCenter}
  >
    <S.DialogContent
      aria-label={ariaLabel}
      $verticalPosition={position}
      className={className}
      $maxWidth={maxWidth}
    >
      <S.DialogClose
        type="button"
        aria-label="dialog close"
        className="close-dialog"
        onClick={onClose}
        data-testid="close-dialog"
      />
      {children}
    </S.DialogContent>
  </S.DialogOverlay>
);
