import styled, { css } from 'styled-components';
import type { RuleSet } from 'styled-components';
import { colors, media } from '../../../../shared/core/styles';
import { SiteType } from '~/utilities/graphql/codegen';

const GridPriceValueStyles = css<{ $hasDiscountPrice: boolean }>`
  margin-right: 5px;
  font-weight: ${({ $hasDiscountPrice, theme }) =>
    $hasDiscountPrice ? theme.overrides.font?.weight.bold : theme.overrides.font?.weight.medium};
  font-size: ${({ theme }) => theme.font.size[3]};
  line-height: 1.4;
`;

const AafPriceValueStyles = css`
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.14px;
`;

const GstarProductPriceWrapper = css``;

const AafProductPriceWrapper = css`
  min-height: 56px;
  margin: 8px 0;
  color: ${colors.NERO_GREY};
`;

const GstarPriceInfo = css`
  line-height: 1.4;
`;

const AafPriceInfo = css`
  height: 24px;
  font-style: normal;
  line-height: 16px;
  margin: 8px 0;
`;

const PriceValueConfig: Record<SiteType, RuleSet<{ $hasDiscountPrice: boolean }>> = {
  [SiteType.Gstar]: GridPriceValueStyles,
  [SiteType.Outlet]: GridPriceValueStyles,
  [SiteType.Employeeshop]: GridPriceValueStyles,
  [SiteType.Aaf]: AafPriceValueStyles,
};

const PriceInfoConfig: Record<SiteType, RuleSet> = {
  [SiteType.Gstar]: GstarPriceInfo,
  [SiteType.Outlet]: GstarPriceInfo,
  [SiteType.Employeeshop]: GstarPriceInfo,
  [SiteType.Aaf]: AafPriceInfo,
};

const ProductPriceWrapperConfig: Record<SiteType, RuleSet> = {
  [SiteType.Gstar]: GstarProductPriceWrapper,
  [SiteType.Outlet]: GstarProductPriceWrapper,
  [SiteType.Employeeshop]: GstarProductPriceWrapper,
  [SiteType.Aaf]: AafProductPriceWrapper,
};

export const S = {
  ProductPriceWrapper: styled.div<{
    $lowestPriceDisplay: string;
    $isQuickviewOverlay?: boolean;
  }>`
    height: ${({ $isQuickviewOverlay, theme }) =>
      $isQuickviewOverlay && theme.siteType === SiteType.Gstar && '52px'};
    font-size: ${({ theme }) => theme.font.size[3]};
    line-height: 1;
    max-width: 100%;
    position: relative;
    font-weight: 700;
    ${({ theme }) => ProductPriceWrapperConfig[theme.siteType]}

    @media ${({ theme }) => media(theme).greaterThan('lg')} {
      /* overflow: hidden; RRP Tooltip require overflow visible */
      padding: 0;
      text-align: left;
    }

    @media ${({ theme }) => media(theme).between('xs', 'lg')} {
      padding: 0;
      text-align: ${({ $lowestPriceDisplay }) =>
        $lowestPriceDisplay === 'NEVER' ? 'center' : 'left'};
    }
  `,
  PriceDiscount: styled.span`
    color: ${({ theme }) => theme.colors.content.primary};
    font-weight: ${({ theme }) => theme.overrides.font?.weight.regular};
    text-decoration: line-through;
  `,
  PriceValue: styled.strong<{
    $basePriceColor?: string;
    $hasDiscountPrice: boolean;
  }>`
    ${({ $basePriceColor, $hasDiscountPrice, theme }) =>
      $hasDiscountPrice
        ? `color: ${$basePriceColor || theme.colors.content.sale};`
        : `color: ${theme.colors.content.primary};`}

    ${({ theme }) => PriceValueConfig[theme.siteType]}
  `,
  PriceTax: styled.span`
    color: ${({ theme }) => theme.colors.content.secondary};
    font-size: ${({ theme }) => theme.font.size[2]};
    font-style: normal;
    font-weight: ${({ theme }) => theme.overrides.font?.weight.regular};
    white-space: nowrap;
    vertical-align: bottom;
  `,

  PriceLowest30Days: styled.span`
    font-size: ${({ theme }) => theme.font.size[1]};
    font-weight: ${({ theme }) => theme.overrides.font?.weight.regular};
    line-height: 1.4;
    color: ${({ theme }) => theme.colors.content.secondary};
  `,

  PriceInfo: styled.div<{ $hasRrpTooltip?: boolean }>`
    ${({ $hasRrpTooltip }) => {
      if (!$hasRrpTooltip) {
        return `
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
        `;
      }

      return '';
    }}
    display:flex;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    font-size: ${({ theme }) => theme.font.size[4]};
    font-style: normal;
    line-height: 16px;
    ${({ theme }) => PriceInfoConfig[theme.siteType]}

    @media ${({ theme }) => media(theme).greaterThan('lg')} {
      justify-content: start;
    }
  `,

  PriceOriginal: styled.span<{ $isOnlyLowestPrice?: boolean | null }>`
    text-decoration: line-through;
    font-weight: ${({ theme }) => theme.overrides.font?.weight.medium};
    color: ${colors.FIORD_GREY};
    font-size: ${({ theme }) => theme.font.size[3]};

    @media ${({ theme }) => media(theme).greaterThan('xs')} {
      margin-right: ${({ $isOnlyLowestPrice }) => ($isOnlyLowestPrice ? '0' : '5px')};
    }
  `,

  TooltipContainer: styled.span`
    position: relative;
    display: inline-block;
  `,
  TooltipIcon: styled.span<{ $active?: boolean }>`
    color: ${({ $active }) => ($active ? colors.BLACK : colors.FIORD_GREY)};
    font-size: ${({ theme }) => theme.font.size[4]};
    line-height: 16px;
    transition: color 0.25s;

    &::before {
      font-family: gstar-icons;
      font-style: normal;
      content: '\\F155';
      margin: 0 0.2em 0 0;
      font-size: ${({ theme }) => theme.font.size[3]};
    }

    &:hover {
      cursor: pointer;
    }

    &:hover,
    &:focus {
      color: ${colors.BLACK};
    }
  `,
  IconArrow: styled.span`
    position: relative;
    display: block;
    top: 0;
    left: 2px;
    z-index: 55;
    transform: rotate(180deg);

    &::before,
    &::after {
      position: absolute;
      display: block;
      content: '';
      border: 10px solid transparent;
    }

    &::before {
      top: -10px;
      border-top-color: ${colors.DISABLED_GREY};
    }

    &::after {
      top: -12px;
      border-top-color: ${colors.WHITE};
    }
  `,
};
