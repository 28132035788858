/* eslint-disable react/no-unknown-property */
import { useEffect, useRef, useState, useCallback } from 'react';
import type { ReactElement, SyntheticEvent } from 'react';
import styled, { css } from 'styled-components';
import Head from 'next/head';
import { media } from '../../../shared/core/styles';
import { useLayoutDesignContext } from '../../../../utilities/context/dynamic/LayoutDesignContext';
import { imageBlurData } from '../../../../utilities/constants/base64Images';
import { useAppContext } from '~/utilities/context/static/AppContext';
import { SiteType } from '~/utilities/graphql/codegen';

export interface ProductImageProperty {
  url: string;
  altText?: string;
  onClick?: () => void;
  profile?: string;
}

export interface CarouselImageProperty {
  original: ProductImageProperty;
  wide?: ProductImageProperty;
  lazyLoad?: boolean;
  scrolledView?: boolean;
}

export const createProfileRegExp = (shouldUseDarkBackgroundImgs: boolean) =>
  new RegExp(`^${shouldUseDarkBackgroundImgs ? 'M' : 'Z'}0\\dW?$`);

export const preferredImageProfiles = [
  'M01',
  'M01W',
  'M02',
  'M02W',
  'M03',
  'M03W',
  'M04',
  'M04W',
  'M05',
  'M05W',
  'M06',
  'M06W',
  'M07',
  'M07W',
  'M08',
  'M08W',
  'Z01',
  'Z01W',
  'Z02',
  'Z02W',
  'Z03',
  'Z03W',
  'Z04',
  'Z04W',
  'Z05',
  'Z05W',
  'Z06',
  'Z06W',
  'Z07',
  'Z07W',
  'Z08',
  'Z08W',
];

const ratioMapping: Map<string, number> = new Map([
  ['1024.1', 675],
  ['767.1', 500],
  ['428.1', 630],
  ['428', 428],
]);

const scrolledViewRatioMapping: Map<string, number> = new Map([
  ['1024.1', 950],
  ['767.1', 767],
  ['428.1', 630],
  ['428', 428],
]);

export const productImagePropertyInitValue = {
  url: '',
  altText: undefined,
  onClick: undefined,
  profile: undefined,
};

const S = {
  Wrapper: styled.div`
    position: relative;
    width: 100%;
    height: 100%;
  `,
  ImageWrapper: styled.div<{ $hasStickyProductImageGallery?: boolean }>`
    ${({ $hasStickyProductImageGallery }) =>
      !$hasStickyProductImageGallery &&
      css`
        display: flex;
        justify-content: center;
      `}

    position: inherit;
    height: 100%;
    width: 100%;

    picture {
      position: relative;
      display: block;
      height: 100%;
    }

    @media ${({ theme }) => media(theme).lessThan('lg')} {
      width: 100%;
    }
  `,

  Image: styled.img<{
    fetchpriority?: string;
    $hasStickyProductImageGallery?: boolean;
    isAafSite?: boolean;
  }>`
    width: auto;
    height: 100%;
    aspect-ratio: 1 / 1;
    transition: opacity 250ms ease-in-out 0s;
    opacity: 0;

    &.isLoaded {
      opacity: 1;
    }

    @media ${({ theme }) => media(theme).lessThan('sm')} {
      aspect-ratio: 1 / 1;

      ${({ isAafSite }) =>
        isAafSite &&
        css`
          aspect-ratio: 4 / 5;
        `}
    }

    @media ${({ theme }) => media(theme).between('sm', 'lg')} {
      aspect-ratio: 1922 / 675;

      ${({ $hasStickyProductImageGallery }) =>
        !$hasStickyProductImageGallery &&
        css`
          transform: translateX(11%);
        `}
    }

    @media ${({ theme }) => media(theme).greaterThan('sm')} {
      aspect-ratio: 1922 / 675;

      ${({ $hasStickyProductImageGallery }) =>
        // center image by setting x-offset based on ratio of 0.61
        $hasStickyProductImageGallery &&
        css`
          &.isLoaded {
            object-fit: cover;
            object-position: ${100 - 0.61 * 100}% 50%;
            width: calc(100% + (50% * 0.61));
          }
        `}

      ${({ isAafSite }) =>
        isAafSite &&
        css`
          &.isLoaded {
            object-position: 50% 50%;
            max-width: 100%;
          }
        `}
    }

    @media (prefers-reduced-motion) {
      transition: none;
    }
  `,
};

export const ProductImage = ({
  original: { url, altText = '', onClick, profile },
  wide,
  lazyLoad,
  scrolledView = false,
}: CarouselImageProperty): ReactElement => {
  const imageWrapperRef = useRef<HTMLDivElement>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const { hasStickyProductImageGallery } = useLayoutDesignContext();

  const { siteType } = useAppContext();
  const isAafSite = siteType === SiteType.Aaf;

  const getResponsiveImageUrl = useCallback(
    (width: number) => {
      let urlTemplate = url;
      const height = scrolledView
        ? scrolledViewRatioMapping.get(String(width))
        : ratioMapping.get(String(width));

      if (!height) {
        return '';
      }

      if (width >= 767.1 && !isAafSite) {
        if (wide) {
          urlTemplate = wide.url;
        } else {
          urlTemplate = url.replace(/([ZM]0\d)/, '$1W');
        }
      }

      const templateReplace = (val: number): string =>
        urlTemplate?.replace('{{dimensions}}', `h_${val}`).replace('h_620', `h_${val}`) ?? '';

      return `${templateReplace(height)}, ${templateReplace(height * 2)} 2x`;
    },
    [url, wide, scrolledView, isAafSite]
  );

  useEffect(() => {
    setIsLoaded(false);
  }, [url]);

  useEffect(() => {
    const element = imageWrapperRef.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          [...entry.target.querySelectorAll('source')].forEach((el: HTMLSourceElement) => {
            if (el.dataset.srcset) {
              el.setAttribute('srcset', el?.dataset?.srcset);
            }
          });
        }
      },
      {
        root: null,
        rootMargin: '0px -1px 0px -1px',
        threshold: 0.01,
      }
    );

    if (element) {
      setIsLoaded(false);
      observer.observe(element);
    }

    return () => {
      if (element) {
        setIsLoaded(false);
        observer.unobserve(element);
      }
    };
  }, [imageWrapperRef]);

  const loadingStateHandler = useCallback((event: SyntheticEvent) => {
    const currentSrc = (event.target as HTMLImageElement)?.currentSrc;

    // only trigger onload when currentSrc is a non-1x1px gif
    if (currentSrc && !/data:image\/gif/.test(currentSrc)) {
      setIsLoaded(true);
    }
  }, []);

  return (
    <S.Wrapper>
      {!lazyLoad && (
        <Head>
          <link
            rel="preload"
            as="image"
            imageSrcSet={getResponsiveImageUrl(1024.1)}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            fetchpriority="high"
            media="(min-width: 1024.1px)"
          />
          <link
            rel="preload"
            as="image"
            imageSrcSet={getResponsiveImageUrl(767.1)}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            fetchpriority="high"
            media="(min-width: 767.1px) and (max-width: 1024px)"
          />
          <link
            rel="preload"
            as="image"
            imageSrcSet={getResponsiveImageUrl(428.1)}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            fetchpriority="high"
            media="(min-width: 428.1px) and (max-width: 428px)"
          />
          <link
            rel="preload"
            as="image"
            imageSrcSet={getResponsiveImageUrl(428)}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            fetchpriority="high"
            media="(max-width: 428px)"
          />
        </Head>
      )}
      <S.ImageWrapper
        ref={imageWrapperRef}
        $hasStickyProductImageGallery={hasStickyProductImageGallery}
      >
        <picture>
          {[...ratioMapping.keys()].map((entry: string, index, array) => {
            const url = getResponsiveImageUrl(Number(entry));

            return (
              <source
                key={index}
                {...(lazyLoad ? { 'data-srcset': url } : { srcSet: url })}
                media={`(${array.length - 1 === index ? 'max' : 'min'}-width: ${entry}px)`}
              />
            );
          })}
          <S.Image
            onLoad={loadingStateHandler}
            onError={loadingStateHandler}
            className={isLoaded || !lazyLoad ? 'isLoaded' : ''}
            src={imageBlurData}
            alt={altText}
            decoding="async"
            onClick={onClick}
            data-profile={profile}
            fetchpriority={lazyLoad ? 'auto' : 'high'}
            $hasStickyProductImageGallery={hasStickyProductImageGallery}
            isAafSite={isAafSite}
          />
        </picture>
      </S.ImageWrapper>
    </S.Wrapper>
  );
};
